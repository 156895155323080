import React from 'react';
import useDelegate from '../../hooks/useDelegate';
import './DelegateSelector.sass';
import {useWallet} from 'use-wallet';

const DelegateSelector = () => {
  const {account} = useWallet()
  const {delegates, selectDelegate, selectedDelegate} = useDelegate();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = +e.target.value;
    selectDelegate(delegates[index])
  }

  const getSelectedDelegateIndex = (): number => {
    return selectedDelegate && delegates.findIndex(delegate => selectedDelegate.address === delegate.address) || 0
  }

  return <select className='select' value={getSelectedDelegateIndex()} onChange={e => handleChange(e)}>
    <option value={null}>Metamask: {account}</option>
    {
      delegates && delegates.length && delegates.map((delegate, i) => {
        return <option value={i} key={i}>Delegate: {delegate.address}</option>
      })
    }
  </select>
}

export default DelegateSelector
