import React, {createContext, useState} from 'react';

export interface NotificationContextI {
  textNotification: string,
  closeNotification: () => void,
  openNotification: (text: string, isAutoClose?: boolean) => void
}

export const NotificationContext = createContext<NotificationContextI>(null);

const NotificationProvider: React.FC = ({children}) => {
  const [textNotification, setTextNotification] = useState<string>('')
  const [timeoutIndex, setTimeoutIndex] = useState<number>(null)

  const closeNotification = () => {
    setTextNotification('')
    clearTimeout(timeoutIndex);
  }

  const openNotification = (text: string, isAutoClose?: boolean) => {
    setTextNotification(text);
    if (isAutoClose) {
      setTimeoutIndex(setTimeout(closeNotification, 4500))
    }
  }

  return <NotificationContext.Provider value={{textNotification, closeNotification, openNotification}}>
    {children}
  </NotificationContext.Provider>
}

export default NotificationProvider
