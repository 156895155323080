import React from 'react';
import './Notification.sass';
import useNotification from '../../hooks/useNotification';
import IconButton from '../IconButton/IconButton';
import CloseIcon from '../icons/CloseIcon/CloseIcon';

const Notification = () => {
  const {textNotification, closeNotification} = useNotification()
  return textNotification && <div className="notification">
    <IconButton size='24px' padding='4px' isAction={true} click={closeNotification} className="notification__close-btn">
      <CloseIcon/>
    </IconButton>
    <p className="notification__text">{textNotification}</p>
  </div>
}

export default Notification;
