import {provider} from 'web3-core';
import {useWallet} from "use-wallet";
import Web3 from 'web3';
import {AbiItem} from 'web3-utils';
import {Contract} from 'web3-eth-contract';

const useContract = () => {
  const {ethereum}: { ethereum: provider } = useWallet()
  const web3 = new Web3(ethereum)
  const createContract = (abi: AbiItem[], contractAddress: string): Contract => {
    return new web3.eth.Contract(abi, contractAddress);
  }
  return {createContract}
}

export default useContract;
