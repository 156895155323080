import React, {createContext, useCallback, useEffect, useState} from 'react';
import {useWallet} from "use-wallet";
import useDelegateRegistry from '../hooks/useDelegateRegistry';
import useDelegateAddressFactory from '../hooks/useDelegateAddressFactory';
import {Contract} from 'web3-eth-contract';
import {Delegate} from '../models/delegate.model';
import useDelegateAddress from '../hooks/useDelegateAddress';
import {CONFIG} from '../models/constants/other';

export interface DelegateContext {
  delegates?: Delegate[];
  isLoadingDelegates: boolean,
  selectedDelegate?: Delegate,
  selectDelegate?: React.Dispatch<Delegate>,
  createDelegate?: () => Promise<boolean>
}

export const DelegateContext = createContext<DelegateContext>({isLoadingDelegates: false});

const _createDelegate = (contract: Contract, addressFrom: string): Promise<any> => {
  return contract.methods
    .createDelegate(CONFIG[process.env.REACT_APP_ENVIRONMENT].MASTER_CHEF_CONTRACT, CONFIG[process.env.REACT_APP_ENVIRONMENT].OWNERSHIP_TOKEN_CONTRACT)
    .send({from: addressFrom});
}

const DelegateProvider: React.FC = ({children}) => {

  const [delegates, setDelegates] = useState<Delegate[]>([])
  const [isLoadingDelegates, setIsLoadingDelegates] = useState(true)
  const [delegate, setDelegate] = useState<Delegate>(null)
  const [createdDelegate, setCreatedDelegate] = useState<string>('')
  const { account } = useWallet()
  const {delegateAddressFactoryContract} = useDelegateAddressFactory()
  const {getDelegatesLength, getDelegates} = useDelegateRegistry();
  const {owner} = useDelegateAddress()

  const fetchDelegates = useCallback(async () => {
    setIsLoadingDelegates(true)
    const length = await getDelegatesLength(account)
    const list = await getDelegates(account, 0, +length);
    let delegateInfoList: Delegate[] = [];
    for await (let delegate of list) {
      const isOwnerOfDelegate = await isOwner(delegate);
      delegateInfoList.push(new Delegate(delegate, isOwnerOfDelegate));
    }
    setDelegates(delegateInfoList)
    setIsLoadingDelegates(false)
  }, [account, createdDelegate])

  const isOwner = async (address: string): Promise<boolean> => {
    const ownerAccount = await owner(address);
    const lowerOwnerAccount = ownerAccount.toLowerCase();
    return (account.toLowerCase() === lowerOwnerAccount);
  }

  useEffect(() => {
    if (account) {
      fetchDelegates()
    }
  }, [account, createdDelegate])

  const createDelegate = async () => {
    try {
      const res = await _createDelegate(delegateAddressFactoryContract, account)
      setCreatedDelegate(res.blockHash)
      console.log('res', res);
      return true
    } catch (e) {
      console.error(e);
      return false
    }
  }

  const value = {
    delegates,
    isLoadingDelegates,
    selectedDelegate: delegate,
    selectDelegate: setDelegate,
    createDelegate
  }

  return <DelegateContext.Provider value={{...value}}>
    {children}
  </DelegateContext.Provider>
}

export default DelegateProvider;
