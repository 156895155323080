import React from 'react';
import './PieceOfCakeIcon.sass';

const PieceOfCakeIcon: React.FC<{size: string}> = (props: {size: string}) => {
  const {size} = props;
  return <div className="piece-of-cake-icon" style={{width: size, height: size}}>
    <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 17.7792C0 18.4538 0.550605 19 1.23076 19H27.757C28.4371 19 28.9877 18.4538 28.9877 17.7792V11.2252C29.0525 10.8076 28.8582 10.4221 28.4695 10.1972L10.1052 0.366267C9.13356 -0.147767 7.93519 -0.11564 6.99592 0.430521C0.485827 4.38216 0.0323885 10.7755 0 11.0325C0 11.0967 0 11.161 0 11.2252V17.7792ZM8.03235 2.13326C8.35624 1.9405 8.77729 1.9405 9.13356 2.13326L24.0971 9.94015C24.1942 9.97228 24.1618 10.1329 24.0647 10.1329H10.3967C10.17 10.1329 10.0081 10.3257 10.0081 10.5184V14.1167C10.0081 14.9199 9.32789 15.5624 8.51818 15.5303C7.74086 15.4981 7.15786 14.7913 7.15786 14.0524V10.5184C7.15786 10.2935 6.96353 10.1329 6.73681 10.1329H2.29959C2.23481 10.1329 2.20242 10.0687 2.20242 10.0044C2.62347 8.14103 3.98379 4.57492 8.03235 2.13326Z" fill="#08AEEA"/>
    </svg>
  </div>
}

export default PieceOfCakeIcon
