import { useCallback } from 'react'

import { useWallet } from 'use-wallet'

import useMasterChef from './useMasterChef';
import {Contract} from 'web3-eth-contract';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';

const useReward = (pid: number) => {
  const {account}: { account: string} = useWallet()
  const {masterChefContract} = useMasterChef()
  const {selectedDelegate} = useDelegate()
  const {callMethod} = useDelegateAddress()

  const harvest = async (masterChefContract: Contract, pid: number): Promise<string> => {
    return callMethod(selectedDelegate.address, masterChefContract, 'deposit', false, pid, '0');
  }

  const handleReward = useCallback(async () => {
    const txHash = await harvest(masterChefContract, pid)
    console.log(txHash)
    return txHash
  }, [account, pid])

  return { onReward: handleReward }
}

export default useReward
