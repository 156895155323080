export class Delegate {
  part: number
  constructor(public address: string, public isOwner: boolean) {
  }

  getDisplayAddress(): string {
    return this.address.slice(0, 5) + '...' + this.address.slice(-8)
  }
}

export class TransferOwnershipData {
  address = ''
  amount = ''
}
