import React from 'react';
import {useHistory} from 'react-router-dom';
import IconButton from '../IconButton/IconButton';
import BackIcon from '../icons/BackIcon/BackIcon';

interface BackBtnI {
  path?: string
}
const BackBtn: React.FC<BackBtnI> = (props: BackBtnI) => {
  const {path} = props
  const {goBack, push} = useHistory();
  return <IconButton size='18px' padding='2px' click={() => path ? push(path) : goBack()} isAction={true}>
      <BackIcon/>
    </IconButton>
}

export default BackBtn
