import {CONFIG} from './constants/other';

export class TokenInfo {
  address: string
  lockBalance = 0
  symbol: string
  constructor(public tokenKey: TOKENS, public balance: number = 0) {
    this.symbol = CONFIG[process.env.REACT_APP_ENVIRONMENT][tokenKey].name
    this.address = TOKEN_ADDRESS_MAP[tokenKey]
  }
}

export enum TOKENS {
  SUSHI = 'SUSHI',
  LP = 'LP'
}

type TokenMapAddress = { [key in TOKENS]: string }

export const TOKEN_ADDRESS_MAP: TokenMapAddress = {
  [TOKENS.SUSHI]: CONFIG[process.env.REACT_APP_ENVIRONMENT].SUSHI.address,
  [TOKENS.LP]: CONFIG[process.env.REACT_APP_ENVIRONMENT].LP.address
}



export class SendTokenData {
  address = ''
  amount = ''
}
