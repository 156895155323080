import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import useBlock from './useBlock';
import useMasterChef from './useMasterChef';
import useDelegate from '../../hooks/useDelegate';
import {Contract} from 'web3-eth-contract';
import useDelegateAddress from '../../hooks/useDelegateAddress';

const useEarnings = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const {account}: { account: string} = useWallet()
  const block = useBlock()
  const {masterChefContract} = useMasterChef()
  const {selectedDelegate} = useDelegate()
  const {callMethod} = useDelegateAddress()

  const getEarned = async (masterChefContract: Contract, pid: number, account: string): Promise<string> => {
    if (selectedDelegate) {
      return callMethod(selectedDelegate.address, masterChefContract, 'pendingSushi',true ,pid, selectedDelegate.address);
    } else {
      return callMethod(null, masterChefContract, 'pendingSushi',true , pid, account);
    }
  }

  const fetchBalance = useCallback(async () => {
    const balance = await getEarned(masterChefContract, pid, account)
    setBalance(new BigNumber(balance))
  }, [account, selectedDelegate])

  useEffect(() => {
    if (account) {
      fetchBalance()
    }
  }, [account, block, setBalance, selectedDelegate])

  return balance
}

export default useEarnings
