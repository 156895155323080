import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import useBlock from './useBlock';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';
import {Contract} from 'web3-eth-contract';

const useTokenBalance = (lpContract: Contract) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const {account, ethereum} = useWallet()
  const block = useBlock()
  const {selectedDelegate} = useDelegate()
  const {callMethod} = useDelegateAddress()

  const getBalance = async () => {
    return callMethod<string>(selectedDelegate.address, lpContract, 'balanceOf', true, selectedDelegate ? selectedDelegate.address : account)
  }

  const fetchBalance = useCallback(async () => {
    if (selectedDelegate) {
      const balance = await getBalance()
      setBalance(new BigNumber(balance))
    }
  }, [account, ethereum, lpContract, selectedDelegate])

  useEffect(() => {
    if (account && ethereum) {
      fetchBalance()
    }
  }, [account, ethereum, setBalance, block, lpContract, selectedDelegate])

  return balance
}

export default useTokenBalance
