import React, {ReactNode} from 'react';
import './IconButton.sass';

interface IconButton {
    click?: () => void;
    children?: ReactNode;
    disabled?: boolean;
    size: string;
    padding: string;
    isAction?: boolean;
    className?: string
}

const IconButton: React.FC<IconButton> = (props) => {
    return <button style={{width:props.size, height: props.size, padding: props.padding}} disabled={props.disabled}
                   className={`${props.isAction ? "action" : ""} icon-button ${props.className ? props.className : ''}`} onClick={props.click}>
        {props.children}
    </button>
}

export default IconButton;
