import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import './Delegates.sass'
import PieIcon from '../../components/icons/PieIcon/PieIcon';
import {delegatePathAction, getPathWithParams} from '../../models/routes';
import useDelegate from '../../hooks/useDelegate';
import PieceOfCakeIcon from '../../components/icons/PieceOfCakeIcon/PieceOfCakeIcon';
import {Delegate} from '../../models/delegate.model';
import Loader from '../../components/Loader/Loader';

const Delegates = () => {
  const {delegates, createDelegate, isLoadingDelegates} = useDelegate();
  const [createDelegateBtnIsDisabled, setCreateDelegateBtnIsDisabled] = useState(false);
  const handleCreateDelegate = async () => {
    setCreateDelegateBtnIsDisabled(true)
    await createDelegate()
    setCreateDelegateBtnIsDisabled(false)
  }

  return <main className="delegates">
    <div className="delegates-card main-card">
      {delegateBody(delegates, isLoadingDelegates)}
      <button disabled={createDelegateBtnIsDisabled} onClick={() => handleCreateDelegate()} className="create-delegate-btn accent-btn">Create delegate</button>
    </div>
  </main>
}

const delegateBody = (delegates: Delegate[], isLoading: boolean) => {
  return <div className="delegates-card__body">
    <h2 className="delegates-card__title">My delegates</h2>
    {isLoading ? <Loader/> :
      delegates && delegates.length ?
      <div className="delegates-card__scroll">
        <ul className="delegates-card__delegates">
          {delegates.map((delegate, index) => {
            return <li key={index} className="delegates-card__delegate">
              <NavLink to={getPathWithParams(delegatePathAction(delegate.address))} className="delegates-card__link">
                <div className="delegates-card__link-body">
                  {delegate.isOwner ?
                    <div className="delegate__icon"><PieIcon size={'44px'}/></div> :
                    <div className="delegate__icon"><PieceOfCakeIcon size={'44px'}/></div>
                  }
                  {delegate.getDisplayAddress()}
                </div>
              </NavLink>
            </li>
          })}
        </ul>
      </div> : emptyState()
    }
  </div>
}

const emptyState = () => {
  return <div className="delegates-card__empty">
    You did not create any delegates yet
  </div>
}

export default Delegates;
