import React from 'react';
import './PieIcon.sass';

const PieIcon: React.FC<{size: string}> = (props: {size: string}) => {
  const {size} = props;
  return <div className="pie-icon" style={{width: size, height: size}}>
    <svg width="34" height="19" viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path d="M30.517 13.3141C29.3327 13.1562 28.3062 12.5246 27.6351 11.5376C26.8455 12.722 25.5033 13.3931 24.082 13.3931C22.6608 13.3931 21.358 12.6825 20.529 11.5376C19.1472 13.5115 16.4232 13.9853 14.4888 12.6035C14.094 12.3272 13.7387 11.9719 13.4228 11.5376C12.0411 13.5115 9.31708 13.9853 7.38264 12.6035C6.98785 12.3272 6.63255 11.9719 6.31672 11.5376C5.64559 12.4851 4.57967 13.1167 3.43479 13.3141C3.2374 13.3536 3.11896 13.5115 3.15844 13.7089C3.15844 13.7484 3.15844 13.7484 3.15844 13.7879L3.86906 16.1171C4.54019 17.8936 6.23776 19.0385 8.13273 18.999H25.8191C27.714 19.0385 29.4511 17.8542 30.0828 16.0776L30.7934 13.8273C30.8723 13.6694 30.7934 13.4326 30.6355 13.3536C30.596 13.3141 30.5565 13.3141 30.517 13.3141Z" fill="#29F39B"/>
        <path d="M33.2014 7.55026L27.9902 3.64189C21.4368 -1.21396 12.5147 -1.21396 5.96125 3.64189L0.750091 7.55026C0.276349 7.90556 0 8.45826 0 9.05044C0 10.5901 1.22383 11.8139 2.76349 11.8139C4.30315 11.8139 5.52698 10.5901 5.52698 9.05044V8.97148C5.52698 8.53722 5.88229 8.18191 6.31655 8.18191C6.75082 8.18191 7.10612 8.53722 7.10612 8.97148V9.05044C7.10612 10.5901 8.32996 11.8139 9.86962 11.8139C11.4093 11.8139 12.6331 10.5901 12.6331 9.05044V8.97148C12.6331 8.53722 12.9884 8.18191 13.4227 8.18191C13.8569 8.18191 14.2122 8.53722 14.2122 8.97148V9.05044C14.2122 10.5901 15.4361 11.8139 16.9757 11.8139C18.5154 11.8139 19.7392 10.5901 19.7392 9.05044V8.97148C19.7392 8.53722 20.0945 8.18191 20.5288 8.18191C20.9631 8.18191 21.3184 8.53722 21.3184 8.97148V9.05044C21.3184 10.5901 22.5422 11.8139 24.0819 11.8139C25.6215 11.8139 26.8454 10.5901 26.8454 9.05044V8.97148C26.8454 8.53722 27.2007 8.18191 27.6349 8.18191C28.0692 8.18191 28.4245 8.53722 28.4245 8.97148V9.05044C28.4245 10.5901 29.6483 11.8139 31.188 11.8139C32.7276 11.8139 33.9515 10.5901 33.9515 9.05044C33.9515 8.45826 33.6751 7.90556 33.2014 7.55026ZM13.2253 2.41806L12.87 3.28658C12.7515 3.60241 12.4752 3.83928 12.1199 3.83928C11.6461 3.83928 11.2908 3.48398 11.2908 3.01023C11.2908 2.65493 11.4882 2.3391 11.8041 2.22066L12.6726 1.86536C12.9095 1.7864 13.1463 1.90484 13.2253 2.14171C13.2648 2.22066 13.2648 2.3391 13.2253 2.41806ZM17.3705 3.48398C17.0547 3.7998 16.502 3.7998 16.1862 3.48398C15.9493 3.2471 15.8703 2.8918 16.0283 2.57597L16.3836 1.70744C16.502 1.51005 16.7783 1.43109 16.9757 1.54953C17.0547 1.58901 17.0942 1.62849 17.1337 1.70744L17.5284 2.57597C17.6469 2.8918 17.6074 3.2471 17.3705 3.48398ZM21.8316 3.83928C21.4763 3.83928 21.1999 3.60241 21.0815 3.28658L20.7262 2.41806C20.6472 2.18119 20.7657 1.94432 21.0025 1.86536C21.0815 1.82588 21.1999 1.82588 21.2789 1.86536L22.1474 2.22066C22.4632 2.3391 22.6606 2.65493 22.6606 3.01023C22.6606 3.48398 22.3053 3.83928 21.8316 3.83928Z" fill="#29F39B"/>
      </g>
    </svg>
  </div>
}

export default PieIcon
