import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import 'focus-visible/dist/focus-visible.min';
import theme from './sushiswap/theme';
import {ThemeProvider} from 'styled-components';
import {UseWalletProvider} from 'use-wallet';
import DelegateProvider from './contexts/DelegateProvider';
import ModalsProvider from './sushiswap/contexts/Modals'
import NotificationProvider from './contexts/NotificationProvider';
import {CONFIG} from './models/constants/other';

const usedChainId = CONFIG[process.env.REACT_APP_ENVIRONMENT].usedChainId;
const chainId = CONFIG[process.env.REACT_APP_ENVIRONMENT].CHAIN_ID[usedChainId];
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <UseWalletProvider chainId={chainId} connectors={{injected: {}}}>
        <DelegateProvider>
          <NotificationProvider>
            <BrowserRouter>
              <ModalsProvider>
                <App />
              </ModalsProvider>
            </BrowserRouter>
          </NotificationProvider>
        </DelegateProvider>
      </UseWalletProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
