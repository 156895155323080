import React, {useCallback, useEffect, useState} from 'react';
import {useParams, useHistory, NavLink} from 'react-router-dom';
import {
  delegatesPathAction,
  getPathWithParams,
  ROUTES_PATH,
  sendTokenPathAction,
  transferOwnershipPathAction
} from '../../models/routes';
import './Delegate.sass'
import BackBtn from '../../components/BackBtn/BackBtn';
import PieIcon from '../../components/icons/PieIcon/PieIcon';
import PieceOfCakeIcon from '../../components/icons/PieceOfCakeIcon/PieceOfCakeIcon';
import useDelegateAddress from '../../hooks/useDelegateAddress';
import {useWallet} from 'use-wallet';
import useToken from '../../hooks/useToken';
import ethLogo from '../../assets/icons/ethereum.png';
import {TOKEN_ADDRESS_MAP, TokenInfo, TOKENS} from '../../models/tokens.model';
import pigIcon from '../../assets/icons/pig.svg';
import lockIcon from '../../assets/icons/lock.svg';
import useOwnershipToken from '../../hooks/useOwnershipToken';
import useNotification from '../../hooks/useNotification';
import useDelegate from '../../hooks/useDelegate';
import useCopy from '../../hooks/useCopy';
import IconButton from '../../components/IconButton/IconButton';
import LaunchIcon from '../../components/icons/LaunchIcon/LaunchIcon';
import {CONFIG} from '../../models/constants/other';

const getDisplayDelegateAddress = (address: string) => {
  return address.slice(0, 4) + '...' + address.slice(-8)
}

const Delegate = () => {
  const {address} = useParams()
  const {delegates, selectDelegate} = useDelegate()
  const {owner, renounce, withdraw} = useDelegateAddress()
  const [isOwner, setIsOwner] = useState(true)
  const [balanceOf, setBalanceOf] = useState(0)
  const [lockedBalanceOf, setLockedBalanceOf] = useState(0)
  const [renounceIsDisabled, setRenounceIsDisabled] = useState(false)
  const [lockBtnIsDisabled, setLockBtnIsDisabled] = useState(false)
  const [tokenIsWithdrawn, setTokenIsWithdrawn] = useState('')
  const [tokens, setTokens] = useState<TokenInfo[]>([])
  const {account} = useWallet();
  const {push} = useHistory();
  const {getBalance} = useToken();
  const {balanceOfInPercent, lockedBalanceOfInPercent, lockToken} = useOwnershipToken();
  const {openNotification} = useNotification()
  const copy = useCopy()

  const selectCurrentDelegate = () => {
    const findDelegate = delegates.find(delegate => delegate.address === address)
    if (findDelegate) {
      selectDelegate(findDelegate)
    }
  }

  useEffect(() => selectCurrentDelegate(), [delegates, account])

  const fetchBalanceOf = useCallback(async () => {
    const balanceOf = await balanceOfInPercent(address)
    setBalanceOf(balanceOf)
  }, [account])

  const fetchLockedBalanceOf = useCallback(async () => {
    const lockedBalanceOf = await lockedBalanceOfInPercent(address)
    setLockedBalanceOf(lockedBalanceOf)
  }, [account])

  const fetchOwner = useCallback(async () => {
    try {
      const ownerAccount = await owner(address);
      const lowerOwnerAccount = ownerAccount.toLowerCase();
      setIsOwner(account.toLowerCase() === lowerOwnerAccount);
    } catch (e) {
      push('/' + ROUTES_PATH.DELEGATES)
    }
  }, [address, account, renounceIsDisabled])

  const setBalanceToToken = (tokenName: TOKENS, balance: number) => {
    setTokens(prevState => {
      const token = prevState && prevState.find(token => token.symbol === tokenName)
      if (token) {
        token.balance = balance
        return prevState;
      } else {
        const newToken = new TokenInfo(tokenName, balance)
        return prevState ? [...prevState, newToken] : [newToken];
      }
    });
  }

  const updateToken = async (tokenName: TOKENS) => {
    const balance = await getBalance(TOKEN_ADDRESS_MAP[tokenName], address)
    if (balance > 0) {
      setBalanceToToken(tokenName, balance);
    }
  }

  const updateSushiToken = useCallback(async () => {
    await updateToken(TOKENS.SUSHI)
  }, [])

  const updateLpToken = useCallback(async () => {
    await updateToken(TOKENS.LP)
  }, [])

  useEffect(() => {
    fetchOwner()
  }, [address, account, renounceIsDisabled])

  useEffect(() => {
    fetchBalanceOf()
    fetchLockedBalanceOf()
  }, [account, lockBtnIsDisabled, renounceIsDisabled])

  useEffect(() => {
    updateSushiToken()
    updateLpToken()
  }, [])

  const handleWithdraw = async (token: TokenInfo) => {
    setTokenIsWithdrawn(token.symbol)
    withdraw(address, token.address)
      .then(res => {
        setTokenIsWithdrawn('')
      })
      .catch(e => {
        console.error('e', e);
        setTokenIsWithdrawn('')
        openNotification('Ooops, withdraw error', true)
      })
  }

  const handleRelinquish = async () => {
    setRenounceIsDisabled(true)
    try {
      await renounce(address)
      setRenounceIsDisabled(false)
    } catch (e) {
      setRenounceIsDisabled(false)
    }
  }

  const handleLock = () => {
    setLockBtnIsDisabled(true)
    lockToken(address)
      .then(res => {
        setLockBtnIsDisabled(false)
      })
      .catch(e => {
        console.error('e', e);
        setLockBtnIsDisabled(false)
      })
  }

  const openAddressInWeb = (address: string) => {
    window.open(`${CONFIG[process.env.REACT_APP_ENVIRONMENT].LINK_ADDRESS_TO_WEB}/${address}`,'_blank');
  }

  return <main className="delegate">
    <div className="delegate-card main-card">
      <div className="delegate-card__header">
        <div className="back-btn-wrap"><BackBtn path={getPathWithParams(delegatesPathAction())}/></div>

        <div className="address__wrap">
          <p className="address" onClick={() => copy(address)}>{getDisplayDelegateAddress(address)}</p>
          <IconButton size="20px" padding="5px" click={() => openAddressInWeb(address)}>
            <LaunchIcon/>
          </IconButton>
        </div>

      </div>

      {isOwner ? ownerInfo(renounceIsDisabled, handleRelinquish) : shareholderInfo(balanceOf + lockedBalanceOf)}

      {!isOwner && <div className="not-owner-info">
        <div className="not-owner-info__header">
          <img src={pigIcon} alt="pig icon" className="icon"/>
          <span>
            <p className="title">Liquid</p>
            <p className="desc">To withdraw funds, you have to lock your share</p>
          </span>
          <p className="part">{balanceOf}%</p>
        </div>
        {balanceOf !== 0 && <div className="not-owner-info__actions">
          <button disabled={lockBtnIsDisabled} onClick={handleLock}
                  className="not-owner-info__action primary-btn">Lock
          </button>
          <span className="spacer"/>
          <NavLink to={getPathWithParams(transferOwnershipPathAction(address))} className="not-owner-info__action transfer-ownership-btn">Transfer ownership</NavLink>
        </div>}
        <div className="not-owner-info__footer">
          <img src={lockIcon} alt="lock icon" className="icon"/>
          <p className="title">Locked</p>
          <p className="part">{lockedBalanceOf}%</p>
        </div>
      </div>}

      {tokens.length ?
        <div className="scroll">
          <ul className="delegate__tokens">
            {tokens.map((token, index) =>
              <li key={index} className="delegate__token">
                <img src={ethLogo} alt="eth icon" className="delegate__token-icon"/>
                <div className="delegate__token-info">
                  <p className="delegate__token-name">{token.symbol}</p>
                  <div className="delegate__token-balances">
                    <div className="delegate__token-balance">
                      <div className="icon"><PieIcon size={'13px'}/></div>
                      <p className="balance">{token.balance}</p>
                    </div>
                  </div>
                </div>
                {isOwner ?
                  <NavLink to={getPathWithParams(sendTokenPathAction(address, token.tokenKey))}
                           className="simple-btn token-action">Send</NavLink> :
                  <button disabled={lockedBalanceOf === 0 || tokenIsWithdrawn === token.symbol}
                          onClick={() => handleWithdraw(token)}
                          className="simple-btn token-action">Withdraw</button>
                }
              </li>
            )}
          </ul>
        </div> :
        emptyTokens()
      }
    </div>
  </main>
}

const ownerInfo = (isDisabled: boolean, renounce: () => void) => {
  return <div className="delegate-card__info">
    <div className="icon"><PieIcon size={'44px'}/></div>
    <div className="info">
      <h3 className="title">Owner</h3>
      <p className="desc">You have total control of this address, relinquish control to transfer ownership</p>
    </div>
    <button disabled={isDisabled} onClick={() => renounce()} className="clear-btn action">Relinquish</button>
  </div>
}

const shareholderInfo = (part: number) => {
  return <div className="delegate-card__info">
    <div className="icon"><PieceOfCakeIcon size={'44px'}/></div>
    <div className="info">
      <h3 className="title">Shareholder</h3>
    </div>
    <div className="part">{part}%</div>
  </div>
}

const emptyTokens = () => {
  return <p className="empty-tokens">You do not have any delegate tokens yet</p>
}


export default Delegate
