import React from 'react';
import './Login.sass';
import {useWallet} from "use-wallet";
import {CONFIG} from '../../models/constants/other';

const Login = () => {
  const { connect } = useWallet()
  return <div className="login">
    <div className="login__wrap wrapper">
      <svg className="login__logo" width="115" height="122" viewBox="0 0 115 122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M97.6989 15.2061C99.8925 17.1229 100.969 20.1989 100.949 24.2289C100.929 28.2571 99.8108 33.1727 97.6916 38.6603C93.4546 49.6314 85.258 62.7842 74.0947 75.5013C62.9314 88.2183 50.9441 98.0586 40.6024 103.695C35.4298 106.514 30.6934 108.268 26.6939 108.819C22.6926 109.37 19.4947 108.711 17.3011 106.794C15.1075 104.877 14.0307 101.801 14.0508 97.7709C14.0708 93.7427 15.1892 88.8271 17.3084 83.3395C21.5454 72.3684 29.742 59.2156 40.9053 46.4985C52.0686 33.7815 64.0559 23.9412 74.3976 18.3047C79.5702 15.4854 84.3066 13.7314 88.3061 13.1806C92.3074 12.6296 95.5054 13.2892 97.6989 15.2061Z" stroke="white"/>
        <line x1="55.0586" y1="12.72" x2="55.0586" y2="52.2442" stroke="white"/>
        <line x1="37.0449" y1="31.7742" x2="72.072" y2="31.7742" stroke="white"/>
        <line x1="55.0586" y1="75.127" x2="55.0586" y2="114.651" stroke="white"/>
        <line x1="37.0449" y1="94.1809" x2="72.072" y2="94.1809" stroke="white"/>
      </svg>
      <h2 className="login__title">Welcome to {CONFIG[process.env.REACT_APP_ENVIRONMENT].APP_NAME}</h2>
      <p className="login__text">Create an unlimited number of delegated addresses and manage your assets way faster</p>
      <button className="login__btn main-btn" onClick={() => connect('injected')}>
        <span className="main-btn__border"/>
        <span className="main-btn__bg"/>
        <span className="main-btn__text">Connect wallet to start</span>
      </button>
    </div>
  </div>
}

export default Login
