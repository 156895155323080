import { useCallback } from 'react'

import { useWallet } from 'use-wallet'

import useMasterChef from './useMasterChef';
import useDelegateAddress from '../../hooks/useDelegateAddress';
import useDelegate from '../../hooks/useDelegate';
import {amountToWei} from '../../utils/formatBalance';

const useUnstake = (pid: number) => {
  const {account} = useWallet()
  const {masterChefContract} = useMasterChef()
  const {selectedDelegate} = useDelegate();
  const {callMethod} = useDelegateAddress()

  const unstake = async (amount: string) => {
    return callMethod(selectedDelegate.address, masterChefContract, 'withdraw', false, pid, amountToWei(amount))
  }

  const handleUnstake = useCallback(async (amount: string) => {
      const txHash = await unstake(amount)
      console.log('unstake trx', txHash)
    }, [account, pid, selectedDelegate])

  return { onUnstake: handleUnstake }
}

export default useUnstake
