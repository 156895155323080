import React, {useEffect} from 'react';
import './App.sass';
import routes, {ROUTES_PATH} from './models/routes';
import {Switch, Route, Redirect} from 'react-router-dom';
import Header from './components/Header/Header';
import {useWallet} from 'use-wallet';
import Login from './pages/Login/Login';
import Notification from './components/Notification/Notification';
import {CONFIG} from './models/constants/other';

const App = () => {
  const {account} = useWallet();

  useEffect(() => {
    const faviconElemApple = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
    const faviconElemIcon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
    faviconElemApple.href = CONFIG[process.env.REACT_APP_ENVIRONMENT].favicon
    faviconElemIcon.href = CONFIG[process.env.REACT_APP_ENVIRONMENT].favicon
    document.title = CONFIG[process.env.REACT_APP_ENVIRONMENT].APP_NAME
  }, [])

  useEffect(() => {
    console.log('account', account);
  }, [account])
  return (
    <div className="app">
      <Header/>
      {!!account ?
        <Switch>
          <Redirect exact from='/' to={ROUTES_PATH.DELEGATES}/>
          {routes.map((route, index) => {
            return <Route key={index} component={route.component} exact={route.exact} path={route.path}/>;
          })}
          <Redirect from='*' to='/'/>
        </Switch> :
        <Login/>
      }

      <Notification/>

    </div>
  );
};

export default App;
