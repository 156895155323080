import React, {useMemo} from 'react';
import './Staking.sass';
import styled from 'styled-components'
import Harvest from '../../sushiswap/Farm/components/Harvest';
import Stake from '../../sushiswap/Farm/components/Stake';
import Spacer from '../../sushiswap/components/Spacer';
import DelegateSelector from '../../components/DelegateSelector/DelegateSelector';
import useContract from '../../hooks/useContract';
import ERC20ABI from './../../sushiswap/constants/abi/ERC20.json'
import {AbiItem} from 'web3-utils';
import {CONFIG} from '../../models/constants/other';

const Staking = () => {
  const config = {
    pid: 0,
    lpAddresses: CONFIG[process.env.REACT_APP_ENVIRONMENT].LP.address,
    name: 'Sushi Party!',
    symbol: 'SUSHI-ETH UNI-V2 LP',
    tokenSymbol: 'SUSHI',
    icon: '🍣',
  }

  const lpTokenAddress = config.lpAddresses;
  const {createContract} = useContract()
  const lpContract = useMemo(() => {
    return createContract(ERC20ABI.abi as AbiItem[], lpTokenAddress)
  }, [])

  return <div className='staking'>
    <DelegateSelector/>
    <Spacer />
    <StyledFarm>
      <StyledCardsWrapper>
        <StyledCardWrapper>
          <Harvest pid={config.pid} />
        </StyledCardWrapper>
        <Spacer />
        <StyledCardWrapper>
          <Stake
            lpContract={lpContract}
            pid={config.pid}
            tokenName={config.symbol.toUpperCase()}
          />
        </StyledCardWrapper>
      </StyledCardsWrapper>
    </StyledFarm>
  </div>
}

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

export default Staking;
