import React from 'react';
import './Header.sass';
import {useWallet} from 'use-wallet';
// import NavTabs from '../NavTabs/NavTabs';
import useCopy from '../../hooks/useCopy';
import {CONFIG} from '../../models/constants/other';

const Header = () => {
  const {account} = useWallet()
  const copy = useCopy()
  const getDisplayAccountName = (account: string): string => {
    if (!account) return ''
    return account.slice(0, 4) + '...' + account.slice(-4)
  }
  return <div className="header">
    <div className="header__wrap wrapper">
      <div className="header__logo-wrap">
        <h1 className="header__logo">{CONFIG[process.env.REACT_APP_ENVIRONMENT].APP_NAME}</h1>
      </div>
      {!!account &&
        <>
          {/*<NavTabs/>*/}
          <div className="account">
            <p className="account__name" onClick={() => copy(account)}>{getDisplayAccountName(account)}</p>
          </div>
        </>
      }

    </div>
  </div>
}

export default Header;
