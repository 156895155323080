interface ConfigApp {
  APP_NAME: string;
  LINK_ADDRESS_TO_WEB: string;
  OWNERSHIP_TOKEN_CONTRACT: string;
  DELEGATE_ADDRESS_FACTORY_CONTRACT: string;
  REGISTRY_CONTRACT: string;
  MASTER_CHEF_CONTRACT: string;
  SUSHI: TokenInfo;
  LP: TokenInfo;
  CHAIN_ID: ChainIds;
  usedChainId: 'main' | 'test';
  favicon: string;
}

interface ChainIds {
  test: number;
  main: number;
}

interface TokenInfo {
  name: string,
  address: string
}

export const CONFIG: { [key: string]: ConfigApp } = {
  MAIN: {
    APP_NAME: 'Apyswap',
    LINK_ADDRESS_TO_WEB: 'https://ropsten.etherscan.io/address',
    OWNERSHIP_TOKEN_CONTRACT: '0xf446940ec0D85CaF15aBAa1f4B84eeAd215Bf14d',
    DELEGATE_ADDRESS_FACTORY_CONTRACT: '0xF1Db07bD403f9B1B92d687d11c9Df57F8691d7C4',
    REGISTRY_CONTRACT: '0xf61Db6DEF43b763C298bd415eAc6AA29e3263Fa1',
    MASTER_CHEF_CONTRACT: '0x12e0bF9c71D67A639475FB06334946E7b8aca4f6',
    SUSHI: {
      name: 'Sushi',
      address: '0xd5295FF0FE1d1C1Fbce4aB73b5fEfF6D29323517'
    },
    LP: {
      name: 'LP',
      address: '0x66DC5Db55B3EB982862fcC5f116d22D2ABD9b090'
    },
    CHAIN_ID: {
      main: 1,
      test: 3
    },
    usedChainId: 'test',
    favicon: '/cryptocurrency.png'
  },
  BSC: {
    APP_NAME: 'Apyswap Bsc',
    LINK_ADDRESS_TO_WEB: 'https://bscscan.com/address',
    OWNERSHIP_TOKEN_CONTRACT: '0x50C4b232710eeA1354829FE909419121D6900ac5',
    DELEGATE_ADDRESS_FACTORY_CONTRACT: '0x5600467048DD1A71308d95f26F95267447C4815d',
    REGISTRY_CONTRACT: '0x9a7c3DE6dD763e5c20f845F2346FA199E0DFbEA6',
    MASTER_CHEF_CONTRACT: '0x73feaa1ee314f8c655e354234017be2193c9e24e',
    SUSHI: {
      name: 'Cake',
      address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    LP: {
      name: 'SYRUP',
      address: '0x009cf7bc57584b7998236eff51b98a168dcea9b0'
    },
    CHAIN_ID: {
      main: 56,
      test: 97
    },
    usedChainId: 'main',
    favicon: '/binance-coin-bnb-logo.png'
  }
}
