import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'

import useMasterChef from './useMasterChef';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';

const useAllowance = (lpContract: Contract) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const {account}: { account: string } = useWallet()
  const {masterChefContract} = useMasterChef()
  const {selectedDelegate} = useDelegate();
  const {callMethod} = useDelegateAddress();

  const fetchAllowance = useCallback(async () => {
    if (selectedDelegate) {
      const allowance = await callMethod<string>(selectedDelegate.address, lpContract, 'allowance', true, selectedDelegate ? selectedDelegate.address : account, masterChefContract.options.address)
      setAllowance(new BigNumber(allowance))
    }
  }, [account, masterChefContract, lpContract, selectedDelegate])

  useEffect(() => {
    if (account && masterChefContract && lpContract) {
      fetchAllowance()
    }
    let refreshInterval = setInterval(fetchAllowance, 10000)
    return () => clearInterval(refreshInterval)
  }, [account, lpContract, selectedDelegate])

  return allowance
}

export default useAllowance
