import MasterChefAbi from '../../models/abi/masterchef.json';
import useContract from '../../hooks/useContract';
import {AbiItem} from 'web3-utils';
import {CONFIG} from '../../models/constants/other';

const useMasterChef = () => {
  const {createContract} = useContract();
  return {masterChefContract: createContract(MasterChefAbi as AbiItem[], CONFIG[process.env.REACT_APP_ENVIRONMENT].MASTER_CHEF_CONTRACT),
    masterChefContractAddress: CONFIG[process.env.REACT_APP_ENVIRONMENT].MASTER_CHEF_CONTRACT}
}

export default useMasterChef;
