import React, {useCallback, useEffect, useState} from 'react';
import './TransferOwnership.sass'
import BackBtn from '../../components/BackBtn/BackBtn';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {TransferOwnershipData} from '../../models/delegate.model';
import useOwnershipToken from '../../hooks/useOwnershipToken';
import Input from '../../components/Input/Input';
import {amountToWei} from '../../utils/formatBalance';
import {useWallet} from "use-wallet";
import {isAddress} from 'web3-utils';
import {delegatePathAction, getPathWithParams} from '../../models/routes';

const TransferOwnership = () => {
  const {account} = useWallet();
  const {address} = useParams()
  const [sendBtnIsDisabled, setSendBtnIsDisabled] = useState(false)
  const [balanceOf, setBalanceOf] = useState(0)
  const {transferToken} = useOwnershipToken()
  const {balanceOfInPercent} = useOwnershipToken();

  const fetchBalanceOf = useCallback(async () => {
    const balanceOf = await balanceOfInPercent(address)
    setBalanceOf(balanceOf)
  }, [account])

  useEffect(() => {
    fetchBalanceOf()
  }, [account, sendBtnIsDisabled])

  const { register, handleSubmit, errors } = useForm<TransferOwnershipData>({
    defaultValues: new TransferOwnershipData()
  });

  const handleSend = (data: TransferOwnershipData) => {
    setSendBtnIsDisabled(true)
    transferToken(address, data.address, amountToWei((+data.amount / 100).toString()))
      .then(res => {
        setSendBtnIsDisabled(false)
      })
      .catch(e => {
        console.error('Transfer ownership error', e);
        setSendBtnIsDisabled(false)
      })
  };

  return <main className="transfer-ownership">
    <div className="transfer-ownership-card main-card">
      <div className="transfer-ownership-card__header">
        <div className="back-btn-wrap"><BackBtn path={getPathWithParams(delegatePathAction(address))}/></div>
        <p className="title">Transfer ownership</p>
      </div>

      <form className="transfer-ownership-card__body" onSubmit={handleSubmit(handleSend)}>

        <div className="input__container">
          <Input name="address"
                 label="Recipient"
                 placeholder="Enter address"
                 register={register({
                   required: {value: true, message: 'Address cannot be empty'},
                   validate: value => !isAddress(value) ? 'Address not valid' : true
                 })}
                 type="text"
                 errors={errors}/>
        </div>

        <div className="input__container">
          <Input name="amount"
                 label="Share"
                 labelRight={`Liquid: ${balanceOf}%`}
                 placeholder="0.00"
                 register={register({
                   required: {value: true, message: 'Amount cannot be empty'},
                   validate: value => isNaN(value) ? 'Amount must be number' : true
                 })}
                 type="text"
                 suffix={'%'}
                 errors={errors}/>
        </div>

        <button disabled={sendBtnIsDisabled} className="primary-btn">Send</button>
      </form>
    </div>
  </main>
}

export default TransferOwnership
