import useContract from './useContract';
import OwnershipTokenAbi from './../models/abi/OwnershipToken.abi.json';
import {AbiItem} from 'web3-utils';
import {Contract} from 'web3-eth-contract';
import {useWallet} from 'use-wallet';
import {getBalanceNumber} from '../utils/formatBalance';
import BigNumber from 'bignumber.js';
import {CONFIG} from '../models/constants/other';

const _balanceOf = (contract: Contract, delegateAddress: string, addressFrom: string): Promise<number> => {
  return contract.methods
    .balanceOf(delegateAddress, addressFrom)
    .call();
}

const _lockedBalanceOf = (contract: Contract, delegateAddress: string, addressFrom: string): Promise<number> => {
  return contract.methods
    .lockedBalanceOf(delegateAddress, addressFrom)
    .call();
}

const _lockToken = (contract: Contract, delegateAddress: string, addressFrom: string): Promise<any> => {
  return contract.methods
    .lockToken(delegateAddress)
    .send({from: addressFrom});
}

const _transferToken = (contract: Contract, delegateAddress: string, addressFrom: string, addressTo: string, amount: string): Promise<any> => {
  return contract.methods
    .transferToken(delegateAddress, addressTo, amount)
    .send({from: addressFrom});
}

const useOwnershipToken = () => {
  const {createContract} = useContract();
  const {account} = useWallet();
  const ownershipTokenContract = createContract(OwnershipTokenAbi as AbiItem[], CONFIG[process.env.REACT_APP_ENVIRONMENT].OWNERSHIP_TOKEN_CONTRACT)

  const balanceOfInPercent = async (delegateAddress: string): Promise<number> => {
    const amount = await _balanceOf(ownershipTokenContract, delegateAddress, account)
    return getBalanceNumber(new BigNumber(amount)) * 100;
  }

  const lockedBalanceOfInPercent = async (delegateAddress: string): Promise<number> => {
    const amount = await _lockedBalanceOf(ownershipTokenContract, delegateAddress, account)
    return getBalanceNumber(new BigNumber(amount)) * 100;
  }

  const lockToken = async (delegateAddress: string): Promise<any> => {
    await _lockToken(ownershipTokenContract, delegateAddress, account)
  }

  const transferToken = async (delegateAddress: string, addressTo: string, amount: string): Promise<any> => {
    await _transferToken(ownershipTokenContract, delegateAddress, account, addressTo, amount)
  }

  return {ownershipTokenContract, balanceOfInPercent, lockedBalanceOfInPercent, lockToken, transferToken}
}

export default useOwnershipToken;
