import DelegateAddressFactoryAbi from './../models/abi/DelegateAddressFactory.abi.json';
import useContract from './useContract';
import {AbiItem} from 'web3-utils';
import {CONFIG} from '../models/constants/other';

const useDelegateAddressFactory = () => {
  const {createContract} = useContract();
  const delegateAddressFactoryContract = createContract(DelegateAddressFactoryAbi as AbiItem[], CONFIG[process.env.REACT_APP_ENVIRONMENT].DELEGATE_ADDRESS_FACTORY_CONTRACT);

  return {delegateAddressFactoryContract}
}

export default useDelegateAddressFactory;
