import useDelegateAddress from './useDelegateAddress';
import {getBalanceNumber} from '../utils/formatBalance';
import BigNumber from 'bignumber.js';
import useContract from './useContract';
import ERC20ABI from '../models/abi/erc20.json';
import {AbiItem} from 'web3-utils';

const useToken = () => {
  const {callMethod} = useDelegateAddress()
  const {createContract} = useContract()
  const getBalance = async (tokenAddress: string, delegateAddress: string): Promise<number> => {
    const contract = createContract(ERC20ABI as AbiItem[], tokenAddress)
    const amount = await callMethod<string>(delegateAddress, contract, 'balanceOf', true, delegateAddress)
    return getBalanceNumber(new BigNumber(amount));
  }

  const sendToken = async (tokenAddress: string, delegateAddress: string, addressTo: string, amount: string): Promise<any> => {
    const contract = createContract(ERC20ABI as AbiItem[], tokenAddress)
    return await callMethod<any>(delegateAddress, contract, 'transfer', false, addressTo, amount)
  }

  return {getBalance, sendToken}
}

export default useToken;

