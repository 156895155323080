import useNotification from './useNotification';

const useCopy = () => {
  const {openNotification} = useNotification()

  return (text: string) => {
    navigator.clipboard.writeText(text)
    openNotification('Copied to clipboard!', true)
  }
}

export default useCopy
