import DelegateAddressAbi from './../models/abi/DelegateAddress.abi.json';
import {Contract} from 'web3-eth-contract';
import {AbiItem} from 'web3-utils';
import {getMixedAbi} from '../utils/eth.utils';
import useContract from './useContract';
import {useWallet} from 'use-wallet';
import {useCallback} from 'react';

const wrapDelegate = (method: any, delegateContract: Contract, contractAddress: string, isCall: boolean, isOwner: boolean) => {
  const calldata = method.encodeABI();
  if (isCall) {
    return delegateContract.methods.callContractStatic(contractAddress, calldata);
  } else {
    if (isOwner) {
      return delegateContract.methods.callContractByOwner(contractAddress, calldata);
    } else {
      return delegateContract.methods.callContract(calldata);
    }
  }
}

const _isOwner = (delegateAddressContract: Contract): Promise<string> => {
  return delegateAddressContract.methods.owner().call();
}

const _renounce = (delegateAddressContract: Contract, address: string): Promise<any> => {
  return delegateAddressContract.methods
    .renounceOwnership()
    .send({from: address});
}

const _withdraw = (delegateAddressContract: Contract, tokenAddress: string, address: string): Promise<any> => {
  return delegateAddressContract.methods
    .withdraw(tokenAddress)
    .send({from: address});
}

const useDelegateAddress = () => {
  const {createContract} = useContract()
  const {account} = useWallet()

  const getDelegateContract = (contractMethodName: string, contract: Contract, delegate: string, isCall: boolean): Contract => {
    const abi = getMixedAbi(
      DelegateAddressAbi as AbiItem[],
      isCall ? 'callContractStatic' : 'callContractByOwner',
      contract.options.jsonInterface,
      contractMethodName);
    return createContract(abi, delegate);
  }

  const callMethod = async <T>(delegate: string | null, contract: Contract, methodName: string, isCall: boolean, ...params: unknown[]): Promise<T> => {
    let method = contract.methods[methodName](...params);
    if (delegate) {
      const delegateContract: Contract = getDelegateContract(methodName, contract, delegate, isCall);
      const ownerAccount = await owner(delegate);
      const lowerOwnerAccount = ownerAccount.toLowerCase();
      const isOwner = account.toLowerCase() === lowerOwnerAccount;
      method = wrapDelegate(method, delegateContract, contract.options.address, isCall, isOwner);
    }

    return isCall ? method.call() : method.send({from: account});
  }

  const owner = useCallback(async (addressDelegate: string): Promise<string> => {
    return await _isOwner(createContract(DelegateAddressAbi as AbiItem[], addressDelegate))
  }, [account])

  const renounce = async (addressDelegate: string): Promise<any> => {
    return await _renounce(createContract(DelegateAddressAbi as AbiItem[], addressDelegate), account)
  }

  const withdraw = async (addressDelegate: string, tokenAddress: string): Promise<any> => {
    return await _withdraw(createContract(DelegateAddressAbi as AbiItem[], addressDelegate), tokenAddress, account)
  }

  return {callMethod, owner, renounce, withdraw}
}

export default useDelegateAddress;
