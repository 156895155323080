import * as React from 'react';
import {useLocation} from 'react-router-dom';
import Delegates from '../pages/Delegates/Delegates';
import Staking from '../pages/Staking/Staking';
import Delegate from '../pages/Delegate/Delegate';
import SendToken from '../pages/SendToken/SendToken';
import TransferOwnership from '../pages/TransferOwnership/TransferOwnership';

interface RouterModel {
  path: string,
  component: React.ComponentType | React.ClassType<any, any, any>,
  exact: boolean,
  auth?: boolean
}

export enum ROUTES_PATH {
  DELEGATES = '/delegates',
  DELEGATE = '/delegates/:address',
  SERVICES = '/services',
  SEND_TOKEN = '/delegates/:address/send-token/:tokenKey',
  TRANSFER_OWNERSHIP = '/delegates/:address/transfer-ownership',
}

export function getPathWithParams(path: PathAction) {
  let result: string = path.path;
  if ('data' in path) {
    const data = path.data;
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] && typeof data[key] === 'string') {
        result = result.replace(`:${key}`, data[key]);
      }
    }
  }
  return result;
}

interface DelegatesPathAction extends PathAction{
  path: ROUTES_PATH.DELEGATES
}

interface DelegatePathAction extends PathAction{
  path: ROUTES_PATH.DELEGATE
  data: { address: string }
}

interface ServicesPathAction extends PathAction{
  path: ROUTES_PATH.SERVICES
}

interface SendTokenPathAction extends PathAction{
  path: ROUTES_PATH.SEND_TOKEN,
  data: { address: string, tokenKey: string }
}

interface TransferOwnershipPathAction extends PathAction{
  path: ROUTES_PATH.TRANSFER_OWNERSHIP,
  data: { address: string }
}

interface PathAction {
  path: ROUTES_PATH,
  data?: { [key: string]: string }
}

export const delegatesPathAction = (): DelegatesPathAction => {return {path: ROUTES_PATH.DELEGATES}}
export const servicesPathAction = (): ServicesPathAction => {return {path: ROUTES_PATH.SERVICES}}
export const delegatePathAction = (address: string): DelegatePathAction => {return {path: ROUTES_PATH.DELEGATE, data: {address}}}
export const sendTokenPathAction = (address: string, tokenKey: string): SendTokenPathAction => {return {path: ROUTES_PATH.SEND_TOKEN, data: {address, tokenKey}}}
export const transferOwnershipPathAction = (address: string): TransferOwnershipPathAction => {return {path: ROUTES_PATH.TRANSFER_OWNERSHIP, data: {address}}}

const routes: RouterModel[] = [
  {
    path: ROUTES_PATH.DELEGATES,
    component: Delegates,
    exact: true
  },
  {
    path: ROUTES_PATH.DELEGATE,
    component: Delegate,
    exact: true
  },
  {
    path: ROUTES_PATH.SERVICES,
    component: Staking,
    exact: false
  },
  {
    path: ROUTES_PATH.SEND_TOKEN,
    component: SendToken,
    exact: true
  },
  {
    path: ROUTES_PATH.TRANSFER_OWNERSHIP,
    component: TransferOwnership,
    exact: true
  }
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export default routes;
