import {useCallback} from 'react';

import {useWallet} from 'use-wallet';

import useMasterChef from './useMasterChef';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';
import {Contract} from 'web3-eth-contract';
import {amountToWei} from '../../utils/formatBalance';

const useStake = (pid: number) => {
  const {account}: { account: string } = useWallet();
  const {masterChefContract} = useMasterChef();
  const {selectedDelegate} = useDelegate();
  const {callMethod} = useDelegateAddress();

  const stake = async (masterChefContract: Contract, pid: number, amount: string): Promise<string> => {
    return callMethod(selectedDelegate.address, masterChefContract, 'deposit', false, pid, amountToWei(amount));
  };

  const handleStake = useCallback(async (amount: string) => {
      try {
        const txHash = await stake(masterChefContract, pid, amount);
      } catch (e) {
        return false
      }

    },
    [account, pid, selectedDelegate]
  );

  return {onStake: handleStake};
};

export default useStake;
