import {AbiItem} from 'web3-utils';
import BigNumber from 'bignumber.js';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const getMixedAbi = (delegateAbi: AbiItem[], delegateMethodName: string, contractAbi?: AbiItem[], contractMethodName?: string): AbiItem[] => {
  const clonedAbi: AbiItem[] = JSON.parse(JSON.stringify(delegateAbi));
  if (!contractAbi || !contractMethodName) {
    return clonedAbi;
  }

  const delegateMethod = clonedAbi.find(v => v.name === delegateMethodName);
  const contractMethod = contractAbi.find(v => v.name === contractMethodName);
  if (delegateMethod && contractMethod) {
    delegateMethod.outputs = contractMethod.outputs;
  } else {
    console.warn('Can not replace delegate abi method');
  }
  return clonedAbi;
}
