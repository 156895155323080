import useContract from './useContract';
import DelegateRegistryAbi from './../models/abi/DelegateRegistry.abi.json';
import {Contract} from 'web3-eth-contract';
import {AbiItem} from 'web3-utils';
import {CONFIG} from '../models/constants/other';

const _getDelegatesLength = (contract: Contract, addressFrom: string): Promise<string> => {
  return contract.methods
    .getDelegatesLength(addressFrom)
    .call();
}

const _getDelegates = (contract: Contract, addressOwner: string, offset: number, count: number): Promise<string[]> => {
  return contract.methods
    .getDelegates(addressOwner, offset, count)
    .call();
}

const useDelegateRegistry = () => {
  const {createContract} = useContract();
  const delegateRegistryContract = createContract(DelegateRegistryAbi as AbiItem[], CONFIG[process.env.REACT_APP_ENVIRONMENT].REGISTRY_CONTRACT)

  const getDelegatesLength = (addressFrom: string): Promise<string> => _getDelegatesLength(delegateRegistryContract, addressFrom);

  const getDelegates = async (addressOwner: string, offset: number, count: number): Promise<string[]> => _getDelegates(delegateRegistryContract, addressOwner, offset, count);

  return {delegateRegistryContract, getDelegatesLength, getDelegates}
}

export default useDelegateRegistry;
