import { useCallback } from 'react'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'

// @ts-ignore
import useMasterChef from './useMasterChef';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';
import {ethers} from 'ethers';

const useApprove = (lpContract: Contract) => {
  const {account}: { account: string} = useWallet()
  const {masterChefContract} = useMasterChef()
  const {selectedDelegate} = useDelegate();
  const {callMethod} = useDelegateAddress();

  const approve = async () => {
    return callMethod(selectedDelegate.address, lpContract, 'approve', false, masterChefContract.options.address, ethers.constants.MaxUint256)
  }

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve()
      console.log('tx', tx);
      return tx
    } catch (e) {
      console.error(e);
      return false
    }
  }, [account, lpContract, masterChefContract, selectedDelegate])

  return { onApprove: handleApprove }
}

export default useApprove
