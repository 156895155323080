import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import useBlock from './useBlock'
import useMasterChef from './useMasterChef';
import {Contract} from 'web3-eth-contract';
import useDelegate from '../../hooks/useDelegate';
import useDelegateAddress from '../../hooks/useDelegateAddress';

const useStakedBalance = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const {account}: { account: string } = useWallet()
  const {masterChefContract} = useMasterChef()
  const block = useBlock()
  const {selectedDelegate} = useDelegate()
  const {callMethod} = useDelegateAddress()


  const getStaked = async (masterChefContract: Contract, pid: number, account: string): Promise<{ amount: string, rewardDebt: string }> => {
    if (selectedDelegate) {
      return callMethod(selectedDelegate.address, masterChefContract, 'userInfo', true, pid, selectedDelegate.address);
    } else {
      return callMethod(null, masterChefContract, 'userInfo', true, pid, account);
    }
  }

  const fetchBalance = useCallback(async () => {
    const {amount = '0'} = await getStaked(masterChefContract, pid, account)
    setBalance(new BigNumber(amount))
  }, [account, pid, selectedDelegate])

  useEffect(() => {
    if (account) {
      fetchBalance()
    }
  }, [account, pid, setBalance, block, selectedDelegate])

  return balance
}

export default useStakedBalance
