import React, {useCallback, useEffect, useState} from 'react';
import './SendToken.sass';
import {useParams} from 'react-router-dom';
import BackBtn from '../../components/BackBtn/BackBtn';
import ethLogo from '../../assets/icons/ethereum.png';
import {useForm} from 'react-hook-form';
import {SendTokenData, TOKEN_ADDRESS_MAP, TokenInfo, TOKENS} from '../../models/tokens.model';
import Input from '../../components/Input/Input';
import useToken from '../../hooks/useToken';
import {useWallet} from 'use-wallet';
import {amountToWei} from '../../utils/formatBalance';
import {isAddress} from 'web3-utils';
import {delegatePathAction, getPathWithParams} from '../../models/routes';
import {CONFIG} from '../../models/constants/other';

const SendToken = () => {
  const {account} = useWallet()
  const {address, tokenKey} = useParams()
  const [sendBtnIsDisabled, setSendBtnIsDisabled] = useState(false)
  const {sendToken, getBalance} = useToken()
  const [token, setToken] = useState<TokenInfo>(null)
  const [tokenSymbol, setTokenSymbol] = useState<string>('')
  const { register, handleSubmit, errors } = useForm<SendTokenData>({
    defaultValues: new SendTokenData()
  });

  const handleSend = (data: SendTokenData) => {
    setSendBtnIsDisabled(true)
    sendToken(token.address, address, data.address, amountToWei(data.amount))
      .then(res => {
        setSendBtnIsDisabled(false)
      })
      .catch(e => {
        console.error('Send token error', e);
        setSendBtnIsDisabled(false)
      })
  };

  const updateToken = useCallback(async () => {
    setTokenSymbol(CONFIG[process.env.REACT_APP_ENVIRONMENT][tokenKey as TOKENS].name);
    const balance = await getBalance(TOKEN_ADDRESS_MAP[tokenKey as TOKENS], address)
    setToken(new TokenInfo(tokenKey, balance))
  }, [account, sendBtnIsDisabled])

  useEffect(() => {
    updateToken()
  }, [account, sendBtnIsDisabled])

  return <main className="send-token">
    <div className="send-token-card main-card">
      <div className="send-token-card__header">
        <div className="back-btn-wrap"><BackBtn path={getPathWithParams(delegatePathAction(address))}/></div>
        <div className="token">
          <img src={ethLogo} alt="eth icon" className="token__icon"/>
          <p className="token__symbol">{tokenSymbol}</p>
        </div>
      </div>

      <form className="send-token-card__body" onSubmit={handleSubmit(handleSend)}>

        <div className="input__container">
          <Input name="address"
                 label="Recipient"
                 placeholder="Enter address"
                 register={register({
                   required: {value: true, message: 'Address cannot be empty'},
                   validate: value => !isAddress(value) ? 'Address not valid' : true
                 })}
                 type="text"
                 errors={errors}/>
        </div>

        <div className="input__container">
          <Input name="amount"
                 label="Amount"
                 labelRight={`Balance: ${token && token.balance}`}
                 placeholder="0.00"
                 register={register({
                   required: {value: true, message: 'Amount cannot be empty'},
                   validate: value => isNaN(value) ? 'Amount must be number' : true
                 })}
                 type="text"
                 suffix={tokenSymbol}
                 errors={errors}/>
        </div>

        <button disabled={sendBtnIsDisabled} className="primary-btn">Send</button>
      </form>
    </div>
  </main>
}

export default SendToken;
